/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { object } from 'prop-types';
import SkeletonStyles from '../../GraphqlSkeletonComponents/SkeletonStyles';

const styles = (theme) => ({
    ...SkeletonStyles,
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '40px',
        margin: '8px 8px 4px',
        width: 'auto',
        borderRadius: '5px',
        [theme.breakpoints.up(767)]: {
            height: '60px',
            borderRadius: 0,
            margin: 0,
        },
    },
    leftText: {
        margin: '.5rem',
        width: '10rem',
    },
    rightImageContainer: {
        margin: '.5rem',
        width: '10rem',
    },
});

const UnderNavTextSkeleton = ({ classes }) => (
    <div id="UnderNavTextSkeleton" className={`${classes.container} ${classes.shimmer}`}>
        <div className={`${classes.leftText} ${classes.shimmer}`}>
            <div className={`${classes.textMD} ${classes.shimmer}`} />
        </div>
        <div className={`${classes.rightImageContainer} ${classes.shimmer}`}>
            <div className={`${classes.block} ${classes.shimmer}`} />
        </div>
    </div>
);

UnderNavTextSkeleton.propTypes = {
    classes: object.isRequired,
};

export default withStyles(styles)(UnderNavTextSkeleton);
