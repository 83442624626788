/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getFeatureFlag } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import {
    getOrderTotalBalance,
    getOrderContainsClubItemPayByCreditCard,
    getOrderContainsSubscriptionItem,
    getPassportItem,
} from '../../../../../state/ducks/Common/Common-Selectors';

const WidgetKlaranBadge = () => {
    useEffect(() => {
        if (typeof window !== 'undefined' && window.KlarnaOnsiteService?.refresh) {
            window.KlarnaOnsiteService.refresh();
        }
    }, []);

    return (
        <div style={{ padding: '5px 0px' }}>
            <klarna-placement
                data-key="top-strip-promotion-badge"
                data-locale="en-US"
            />
        </div>
    );
};

const KlarnaPromotionBadge = () => {
    const orderTotal = useSelector(getOrderTotalBalance);
    const passportItem = useSelector(getPassportItem);
    const ffKlarnaPaymentEnabled = useSelector((state) => getFeatureFlag('is-klarna-payment-enabled')(state));

    const isClubItemInCart = useSelector(getOrderContainsClubItemPayByCreditCard);
    const subscriptionItemInCart = useSelector(getOrderContainsSubscriptionItem);
    const klarnaAllowedAmount = useSelector((state) => getFeatureFlag('klarna-allowed-min-and-max-amount')(state));
    const maxAmount = klarnaAllowedAmount.max || 1000;

    if (ffKlarnaPaymentEnabled
        && !isClubItemInCart
        && subscriptionItemInCart.length === 0
        && orderTotal < maxAmount
        && passportItem.length === 0) {
        return <WidgetKlaranBadge />;
    }

    return null;
};

export default KlarnaPromotionBadge;
