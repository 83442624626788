/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

/**
 * The goal here is to provide a single, well tested, function to retrieve the correct price
 * from a product.prices property and return a consistent object to use in components.
 */

/**
 * @description Get the lower price from a tuple of prices.
 * Uses retail price on equal prices.
 * Returns a useful object to render and switch on.
 * @param { [{value: number, type: string}] } prices
 * @returns {{ hasSale: boolean, error: boolean, value: number, type: string, downFrom: number, displayValue: function(number: optionalValue;), }} price
 */
const getPrice = (prices) => {
    const price = {
        hasSale: false,
        error: false,
        value: 0,
        type: 'retail',
        downFrom: 0,

        /**
         * @description Converts a price.value to a formatted display string
         * @param { number } optionalValue
         * @returns { string }
         */
        displayValue(optionalValue) {
            let value = this.value;
            if (optionalValue !== undefined) {
                value = optionalValue;
            }
            return `$${value}`;
        },
    };

    if (!prices || !prices.length) {
        price.error = true;
        return price;
    }

    let retail = prices[0];
    let sale = prices[1];

    // only recieved one price, finish if true
    if (sale === undefined) {
        price.hasSale = retail.type === 'sale';
        price.value = retail.value;
        price.type = retail.type;
        return price;
    }

    // check for bad values and correct
    if (typeof retail.value === 'string' || typeof sale.value === 'string') {
        // try converting both
        const retailValue = Number(retail.value);
        const saleValue = Number(sale.value);

        if (Number.isNaN(retailValue) || Number.isNaN(saleValue)) {
            // just return an error
            price.error = true;
            return price;
        }

        retail.value = retailValue;
        sale.value = saleValue;
    }

    // swap if mismatched to compare correctly
    if (retail.type !== 'retail') {
        const temp = retail;
        retail = sale;
        sale = temp;
    }

    // compare
    price.hasSale = sale.value < retail.value;

    if (price.hasSale) {
        price.value = sale.value;
        price.type = sale.type;
        price.downFrom = retail.value;
        return price;
    }

    price.value = retail.value;
    price.type = retail.type;
    return price;
};

export default getPrice;
