/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { gql } from '@apollo/client';
import { GRAPHQL_ENV } from '..';

/**
 * @param {object} o accepts all params below as options
 * @param {string} brand domain-name
 * @param {string} locale language
 * @param {string} isBeforeCutOff true / false
 * @param {string} isGuest true / false
 * @param {string} isPassportUser true / false
 * @param {string} variant CONTROL / VARIANT A
 */

const findUnderNavText = (o) => {
    if (!o) {
        return null;
    }

    const brand = o.brand ? o.brand : '1800flowers';
    const locale = o.locale ? o.locale : 'en-us';
    const isBeforeCutOff = o.isBeforeCutOff ? o.isBeforeCutOff : 'false';
    const isPassportUser = o.isPassportUser ? o.isPassportUser : 'false';
    const isGuest = o.isGuest ? o.isGuest : 'false';
    const variant = o.variant ? `variant: "${o.variant}",` : '';
    const viewport = o.viewport ? `viewport: "${o.viewport}",` : '';

    return gql` { findUnderNavText(brand: "${brand}", locale: "${locale}", environment: "${GRAPHQL_ENV}", isBeforeCutOff: "${isBeforeCutOff}", isPassportUser: "${isPassportUser}", isGuest: "${isGuest}", ${variant} ${viewport}) {
                    content
                }
            }`;
};

export default findUnderNavText;
