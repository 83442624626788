/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { withRouter } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { UIDReset, UIDConsumer } from 'react-uid';
import {
    object, string, array, bool, number,
} from 'prop-types';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import HomePageBlock from './HomePageBlock';
import UnderNavTextWidgetContainer from '../GraphqlCommonComponents/GraphqlUnderNavTextWidget/GraphqlUnderNavTextWidgetContainer';
import GraphqlSeoHeadTags from '../GraphqlCommonComponents/GraphqlSeoHeadTags/GraphqlSeoHeadTags';
import SeoHomepageJsonLdSchema from './Partials/SeoHomepageJsonLdSchema/SeoHomepageJsonLdSchema';
import { getFeatureFlag } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';

import { coreBrowsingPageViewSuccessTest } from '../../../helpers/tracking/common/commonTrackingHelpers';
import withPageView from '../../../helpers/tracking/hocs/withPageView';
import RenderWhenVisible from '../../../helpers/componentVisibility/RenderWhenVisible';
import KlarnaPromotionBadge from '../../Checkout/KlarnaPaymentOnSiteMessage/KlarnaPromotionBadge/KlarnaPromotionBadge';
import { trackEvent } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';

const styles = () => ({
    homepage: {
        marginTop: '2rem',
    },
    homePageWithRowGap: {
        display: 'grid',
        rowGap: '2rem',
        gridTemplateColumns: '100%',
        // '& > div:has(>style), > div:has([data-pagetype]), > div:has([data-staticid])': {
        '& > div:has(>style), > div:has([data-pagetype])': {
            display: 'none',
        },
    },
});

const GraphqlHomePageBody = ({
    brand,
    blocks,
    seo,
    page,
    domainURL,
    ssrDeviceType,
    presentationFamily,
    hideUnderNavText,
    hasTopMargin,
    classes,
    foldThreshold,
    abHomepageTestFlagAndTrackData,
}) => {
    // track impression is for ab testing homepage only
    const dispatch = useDispatch();
    const ffHasHomepageTopMargin = useSelector(getFeatureFlag('has-homepage-top-margin'));
    const ffHasHomepageGridStructure = useSelector(getFeatureFlag('has-homepage-grid-structure'));
    if (abHomepageTestFlagAndTrackData.isPathIncludedInHomePageLayoutTest) {
        dispatch(trackEvent({
            eventCategory: abHomepageTestFlagAndTrackData.abTesting.tracking_event_category || 'Category not set in homepage cms entry',
            eventAction: abHomepageTestFlagAndTrackData.abTesting.tracking_event_action || 'Action not set in homepage cms entry',
            eventLabel: abHomepageTestFlagAndTrackData.abTesting.tracking_event_label || 'Label not set in homepage cms entry',
            nonInteraction: true,
            queued: true,
        }));
    }
    let homepageStyle = hasTopMargin || ffHasHomepageTopMargin ? classes.homepage : '';
    if (ffHasHomepageGridStructure) {
        homepageStyle = `${homepageStyle} ${classes.homePageWithRowGap}`;
    }

    return (
        <>
            <SeoHomepageJsonLdSchema brand={brand} domainURL={domainURL} seo={seo} />
            <GraphqlSeoHeadTags seoData={seo} />

            {hideUnderNavText &&  (
                <UnderNavTextWidgetContainer page={page} presentationFamily={presentationFamily} />
            )}

            <div data-pagetype="homepage" className={homepageStyle}>
                {
                    blocks
                        && (
                            <UIDReset>
                                <UIDConsumer>
                                    {(id, uid) => (
                                        blocks.map((block, index) => {
                                            if (index <= foldThreshold) {
                                                return (
                                                    <HomePageBlock
                                                        key={uid(block)}
                                                        block={block}
                                                        ssrDeviceType={ssrDeviceType}
                                                        presentationFamily={presentationFamily}
                                                        brand={brand?.domain}
                                                        hasTopMargin={hasTopMargin}
                                                    />
                                                );
                                            }

                                            return (
                                                <RenderWhenVisible>
                                                    <HomePageBlock
                                                        key={uid(block)}
                                                        block={block}
                                                        ssrDeviceType={ssrDeviceType}
                                                        presentationFamily={presentationFamily}
                                                        brand={brand?.domain}
                                                        hasTopMargin={hasTopMargin}
                                                    />
                                                </RenderWhenVisible>
                                            );
                                        })
                                    )}
                                </UIDConsumer>
                            </UIDReset>
                        )
                }
            </div>

            <KlarnaPromotionBadge />
        </>
    );
};

GraphqlHomePageBody.propTypes = {
    brand: object.isRequired,
    ssrDeviceType: string.isRequired,
    blocks: array,
    seo: object,
    domainURL: string,
    page: object.isRequired,
    presentationFamily: string.isRequired,
    hideUnderNavText: bool.isRequired,
    hasTopMargin: bool.isRequired,
    classes: object.isRequired,
    foldThreshold: number,
    abHomepageTestFlagAndTrackData: object.isRequired,
};

GraphqlHomePageBody.defaultProps = {
    blocks: [],
    seo: {},
    domainURL: '',
    foldThreshold: 4,
};

const enhance = compose(
    withRouter,
    withPageView({
        pageViewSuccessTest: coreBrowsingPageViewSuccessTest,
    }),
    withStyles(styles),
);

export default enhance(GraphqlHomePageBody);
