/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useQuery } from '@apollo/client';
import {
    object, string, bool, func, shape,
} from 'prop-types';
import QueryString from 'qs';
import mbpLogger from 'mbp-logger';
import { useHistory } from 'react-router';
import ConnectedGraphqlUnderNavTextWidget from './GraphqlUnderNavTextWidget';
import { getBrand } from '../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { getUserType, getUserRole } from '../../../../../state/ducks/Member/ducks/Common/Common-Selectors';
import { getSSRDeviceType, getIsBot } from '../../../../../state/ducks/App/App-Selectors';
import { getFeatureFlag } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { trackEvent } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import isAfterCutoff from '../../../../helpers/determineCutoffTime';
import determineLocale from '../../../../helpers/contentstack/determineLocale';
import findUnderNavText from '../../../../gql/queries/findUnderNavText';
import UnderNavTextSkeleton from './UnderNavTextSkeleton';
import { getPassportSignInData } from '../../../../../state/ducks/Passport/Passport-Selectors';
import { openPassportSideBar } from '../../../../../state/ducks/Passport/Passport-Action';
import validatePassport from '../../../../helpers/validatePassport';
import { getPassportData } from '../../../../../state/ducks/Common/Common-Selectors';

/*
    - Homepage, Collections pages floral brands
    - control of ab-testing is done through 'is-ab-unt-enabled'
        * manage groups like passport, registered, guest, canada from here to disable flag for those user types
        * if variant field is available in CMS, variant param will be added to UNT
        * can accept multiple viewports, but will return first match (last edited in CMS) best to stick to 1 type of UNT per group
*/

const GraphqlUnderNavTextWidgetContainer = ({
    brand,
    isBot,
    isCanadianCategory,
    page,
    presentationFamily,
    ssrDeviceType,
    trackUNTEvent,
    userType,
    userRole,
    passportSignInData,
    passportOpenSideBar,
    passportData,
}) => {
    const history = useHistory();
    const ffIsUnderNavTextWidgetEnabled = useSelector(getFeatureFlag('is-under-nav-text-widget-enabled'));
    const ffIsAbUntEnabled = useSelector(getFeatureFlag('is-ab-unt-enabled'));
    const ffWhichUnderNavTextVariant = useSelector(getFeatureFlag('which-under-nav-text-variant'));
    // TODO move to data control from page level
    if (!ffIsUnderNavTextWidgetEnabled) {
        return null;
    }
    // only make query client side
    if (typeof window === 'undefined') return <UnderNavTextSkeleton />;

    /**
     * @intention - determine variant based on featureFlag and bot status
     */
    let variant;
    const getUNTVariant = () => {
        if (ffIsAbUntEnabled) {
            if (!isBot) {
                variant = ffWhichUnderNavTextVariant.variant || 'CONTROL';
                return variant;
            }
            return 'CONTROL';
        }
        return null;
    };

    // each combination should hopefully return 1 result, if multiple first instance grabbed
    const underNavQueryOptions = {
        brand: brand.domain,
        locale: determineLocale(history, isCanadianCategory),
        viewport: ssrDeviceType,
        isBeforeCutOff: !isAfterCutoff(),
        // on sign-in, cookie update does not change props - need userRole to trigger component update
        // TODO move authenticated userType to a prop/redux
        isPassportUser: validatePassport('isActivePassportUser', passportData) || userRole === 'P',
        isGuest: userType === 'G',
        variant: getUNTVariant(),
    };

    const UNT_QUERY = findUnderNavText(underNavQueryOptions);
    const { loading, error, data } = useQuery(UNT_QUERY);

    if (brand?.domain === 'berries' && history?.location?.pathname === '/homepage-fb') {
        return null;
    }

    // sometimes when signing in and out the skeleton sticks, !data seems to cover that
    if (loading && !data) {
        return <UnderNavTextSkeleton />;
    }

    let content;
    if (data?.findUnderNavText) {
        ({ content } = data.findUnderNavText);
    }

    if (!loading && !content?.entries?.[0]) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            query: UNT_QUERY,
            component: 'GraphqlUnderNavTextWidgetContainer.js',
            message: 'No data returned from graphql',
            error,
        });
        return null;
    }

    if (content.entries.length) {
        let unt = content.entries[0];
        // still a possibilty of getting multiple matches, enforcement seems best on viewport field
        if (content.entries.length > 1) {
            const deviceType = ssrDeviceType.toLowerCase();
            unt = content.entries.find((entry) => deviceType === entry?.viewport?.viewport?.find((vp) => deviceType === vp?.toLowerCase())?.toLowerCase());
        }
        // old unts will fail the above logic, need to select one (last edited)
        if (unt === undefined) {
            unt = content.entries[0];
        }

        const {
            tracking_event_category = '',
            tracking_event_action = '',
            tracking_event_label = '',
            unique_id = '',
        } = unt?.ab_testing || {};

        // AB Testing Event Only
        if (tracking_event_category && tracking_event_action && unique_id) {
            trackUNTEvent({
                eventCategory: tracking_event_category,
                eventAction: `${tracking_event_action} variant=${variant} id=${unique_id}`,
                eventLabel: tracking_event_label,
                queued: true,
            });
        }
        // Hide UNT Based On Campaign Id
        const urlParams = page?.search ? QueryString.parse(page?.search?.replace('?', '')) : {};
        const isCampaignId = urlParams?.utm_campaign?.includes('2197213');
        if (!isCampaignId && presentationFamily !== 'food') {
            return (
                <ConnectedGraphqlUnderNavTextWidget
                    data={unt}
                    contentType={page?.contentType}
                    presentationFamily={presentationFamily}
                    trackUNTEvent={trackUNTEvent}
                    passportSignInData={passportSignInData}
                    passportOpenSideBar={() => passportOpenSideBar()}
                />
            );
        }
    }
    return null;
};

const mapStateToProps = (state) => ({
    brand: getBrand(state),
    userType: getUserType(state),
    userRole: getUserRole(state),
    ssrDeviceType: getSSRDeviceType(state),
    isBot: getIsBot(state),
    passportSignInData: getPassportSignInData(state),
    passportData: getPassportData(state),
});

const mapDispatchToProps = (dispatch) => ({
    trackUNTEvent: bindActionCreators(trackEvent, dispatch),
    passportOpenSideBar: bindActionCreators(openPassportSideBar, dispatch),
});

GraphqlUnderNavTextWidgetContainer.propTypes = {
    brand: object.isRequired,
    userType: string.isRequired,
    userRole: string.isRequired,
    page: shape({
        contentType: string.isRequired,
    }).isRequired,
    isCanadianCategory: bool,
    presentationFamily: string,
    ssrDeviceType: string,
    isBot: bool,
    trackUNTEvent: func.isRequired,
    passportSignInData: shape({
        underNavText: string,
    }),
    passportData: object,
    passportOpenSideBar: func,
};

GraphqlUnderNavTextWidgetContainer.defaultProps = {
    isCanadianCategory: false,
    presentationFamily: '',
    ssrDeviceType: '',
    isBot: false,
    passportSignInData: {},
    passportOpenSideBar: () => {},
    passportData: {},
};

export default connect(mapStateToProps, mapDispatchToProps)(GraphqlUnderNavTextWidgetContainer);
