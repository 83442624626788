/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Query } from '@apollo/client/react/components';
import { gql } from '@apollo/client';
import mbpLogger from 'mbp-logger';
import { object, string, bool } from 'prop-types';
import { compose } from 'recompose';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import { GRAPHQL_ENV } from '../../../gql';
import HomePageSkeleton from '../GraphqlSkeletonComponents/HomePageSkeleton';
import GraphqlHomePageBody from './GraphqlHomePageBody';

import { getPresentationFamily, getFeatureFlags } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getBrand } from '../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import { getSSRDeviceType, getIsBot } from '../../../../state/ducks/App/App-Selectors';
import determineLocale from '../../../helpers/contentstack/determineLocale';

const GraphqlHomePage = ({
    brand, page, ssrDeviceType, presentationFamily, featureFlags, history, isBot, flags,
}) => {
    // get JSON list from the feature flag
    const isPathIncludedInHomePageLayoutTest = featureFlags['is-homepage-test-list-enabled']?.includedPathList.includes(page?.path);
    // grab variant value from flag
    const ldVariant = flags?.whichHomepageEntryVariant?.variant?.toUpperCase();
    // render skeleton until ldVariant is present (CONTROL || VARIANT)
    if (!isBot
        // && featureFlags['is-ab-homepage-enabled']
        && isPathIncludedInHomePageLayoutTest
        && !ldVariant) {
        return <HomePageSkeleton />;
    }
    // Will add variant property to query or leave an empty string (default homepage) if no value
    let homepageVariant = '';
    if (ldVariant && isPathIncludedInHomePageLayoutTest) {
        homepageVariant = `, variant: "${ldVariant}"`;
    }
    // tablet needs desktop homepage
    // eslint-disable-next-line no-nested-ternary
    const viewport = ssrDeviceType === 'tablet' ? 'desktop' : ssrDeviceType === 'bot' ? 'desktop' : ssrDeviceType;
    const HOMEPAGE_QUERY = gql`
            query HomePageQuery {
                findHomePage(brand: "${brand['domain-name']}", environment: "${GRAPHQL_ENV}", locale: "${determineLocale(history)}", viewport: "${viewport}"${homepageVariant}, url: "${page?.path || '/'}",query:"{\\"include_dimension\\":true}") {
                    content
                    showUnderNavText
                }
            }
        `;

    return (
        <Query query={HOMEPAGE_QUERY}>
            {({ loading, error, data }) => {
                if (loading) {
                    return <HomePageSkeleton />; // skeleton component
                }

                if (error) {
                    mbpLogger.logError({
                        appName: process.env.npm_package_name,
                        query: HOMEPAGE_QUERY,
                        component: 'GraphqlHomePage.js',
                        message: 'Error loading data from Graphql',
                        env: GRAPHQL_ENV,
                        error,
                    });

                    return null;
                }

                if (!data) {
                    mbpLogger.logWarning({
                        appName: process.env.npm_package_name,
                        query: HOMEPAGE_QUERY,
                        component: 'GraphqlHomePage.js',
                        message: 'No data returned for query',
                        env: GRAPHQL_ENV,
                    });

                    return null;
                }
                if (!data.findHomePage.content) {
                    mbpLogger.logWarning({
                        appName: process.env.npm_package_name,
                        query: HOMEPAGE_QUERY,
                        component: 'GraphqlHomePage.js',
                        message: 'No content returned for query',
                        env: GRAPHQL_ENV,
                    });
                    return null;
                }
                if (data) {
                    const homeData = data?.findHomePage?.content?.entries?.[0] || {};
                    const homeBlocks = homeData.home_blocks || null;
                    const SEO = homeData.seo || {};
                    const domainURL = `https://www.${brand.domain}.com` || '';
                    const hideUnderNavText = featureFlags['is-homepage-undernav-text-hidden'] || false;
                    const hasTopMargin = homeData.page_margin || false;
                    const foldThreshold = homeData.fold_threshold || 4;
                    const abHomepageTestFlagAndTrackData = { abTesting: homeData.ab_testing, isPathIncludedInHomePageLayoutTest };
                    return (
                        <GraphqlHomePageBody
                            brand={brand}
                            page={page}
                            blocks={homeBlocks}
                            seo={SEO}
                            domainURL={domainURL}
                            ssrDeviceType={ssrDeviceType}
                            presentationFamily={presentationFamily}
                            abHomepageTestFlagAndTrackData={abHomepageTestFlagAndTrackData}
                            hideUnderNavText={hideUnderNavText}
                            hasTopMargin={hasTopMargin}
                            foldThreshold={foldThreshold}
                        />
                    );
                }

                return <>No Homepage</>;
            }}
        </Query>
    );
};

GraphqlHomePage.propTypes = {
    brand: object.isRequired,
    page: object.isRequired,
    ssrDeviceType: string.isRequired,
    presentationFamily: string.isRequired,
    featureFlags: object.isRequired,
    history: object.isRequired,
    isBot: bool,
    flags: object.isRequired,
};

GraphqlHomePage.defaultProps = {
    isBot: false,
};

const mapStateToProps = (state) => ({
    brand: getBrand(state),
    ssrDeviceType: getSSRDeviceType(state),
    presentationFamily: getPresentationFamily(state),
    featureFlags: getFeatureFlags(state),
    isBot: getIsBot(state),
});

const enhance = compose(
    withRouter,
    connect(mapStateToProps),
    withLDConsumer(),
);

export default enhance(GraphqlHomePage);
