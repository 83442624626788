/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object, string } from 'prop-types';
import { Helmet } from 'react-helmet';
import brandThemes from '../../../../AppShell/brandtheme';

const seoOpenGraphImage = (seo) => (seo?.og?.length ? seo.og.find((prop) => prop.property === 'og:image')?.content : null);

const brandConfigurationPrimaryLogo = (brand) => (brandThemes[brand?.code]?.logos?.primary_logo?.url ? brandThemes[brand.code].logos.primary_logo.url : null);

const SeoHPJsonLdSchema = ({ brand, domainURL, seo }) => {
    const logoUrl = seoOpenGraphImage(seo) || brandConfigurationPrimaryLogo(brand) || null;
    const logoClause = logoUrl ? `"logo": "${logoUrl}",` : '';
    return (
        <Helmet>
            (
            <script type="application/ld+json">
                {`{"@context": "http://schema.org","@type": "WebSite","url": "${domainURL}","potentialAction": {"@type": "SearchAction","target": "${domainURL}/searchterm/{search_term_string}","query-input": "required name=search_term_string"}}`}
            </script>
            <script type="application/ld+json">
                {`{"@context": "https://schema.org","@type": "Organization","name": "${brand.description}",${logoClause}"url": "${domainURL}","parentOrganization": "1-800-FLOWERS.COM, INC","address": {"@type": "PostalAddress","addressLocality": "Jericho","addressRegion": "NY","postalCode": "11753","streetAddress": " Two Jericho Plaza, Suite 200"},"contactPoint": [{"@type": "ContactPoint","telephone": "+1-800-716-4851","contactType": "Customer Service","sameAs": ["https://www.facebook.com/${brand.domain}/","https://twitter.com/${brand.domain}","https://www.instagram.com/${brand.domain}/","https://www.youtube.com/${brand.domain}","https://www.linkedin.com/company/${brand.domain}-com","https://www.pinterest.com/${brand.domain}/"]}]}`}
            </script>
            )
        </Helmet>
    );
};

SeoHPJsonLdSchema.propTypes = {
    brand: object.isRequired,
    domainURL: string,
    seo: object,
};

SeoHPJsonLdSchema.defaultProps = {
    domainURL: '',
    seo: {},
};

export default SeoHPJsonLdSchema;
